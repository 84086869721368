import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
  Container,
  CardContent,
  CardActions,
  Box,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { api } from "../api/index";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitetoken = location?.state?.sn || queryParams.get("invite_token");
  const [formtoken, setFormtoken] = useState(null);
  const [changelang, setChangelang] = useState("TH");

  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const schemaStep1 = yup.object().shape({
    // idNumber: yup.string().required("กรุณากรอกเลขประจำตัวประชาชนหรือ Passport"),
  });

  const schemaStep2 = yup.object().shape({
    personal_id: yup
      .string()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )
      .matches(
        /^[A-Za-z0-9]+$/,
        changelang === "TH"
          ? "กรุณากรอกเป็นภาษาอังกฤษและตัวเลขเท่านั้น"
          : "Please enter only English letters and numbers"
      )
      .max(
        13,
        changelang === "TH"
          ? "กรุณากรอกไม่เกิน 13 หลัก"
          : "Please enter no more than 13 characters"
      ),
    first_name: yup
      .string()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )
      .matches(
        /^[\p{L}\p{M}\s]+$/u,
        changelang === "TH"
          ? "กรุณากรอกเป็นตัวอักษรเท่านั้น"
          : "Please enter letters only"
      ),
    last_name: yup
      .string()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )
      .matches(
        /^[\p{L}\p{M}\s]+$/u,
        changelang === "TH"
          ? "กรุณากรอกเป็นตัวอักษรเท่านั้น"
          : "Please enter letters only"
      ),
    tel_no: yup
      .string()
      .nullable()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )

      .matches(/^\d+$/, {
        message:
          changelang === "TH"
            ? "กรุณากรอกเป็นตัวเลขเท่านั้น"
            : "Please enter numbers only",
        excludeEmptyString: true,
      })
      .max(
        10,
        changelang === "TH"
          ? "กรุณากรอกไม่เกิน 10 หลัก"
          : "Please enter no more than 10 characters"
      ),
    email: yup
      .string()
      .email(
        changelang === "TH"
          ? "กรุณากรอกเป็นอีเมลเท่านั้น"
          : "Please enter a valid email"
      )
      .nullable(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )
    // .required(
    //   changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
    // )
    ,
    license_plate: yup.string().nullable(),
    company_name: yup
      .string()
    // .required(
    //   changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
    // )
    ,
    followers: yup.array().of(
      yup.object().shape({
        personal_id: yup
          .string()
          .required(
            changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
          )
          .matches(
            /^[A-Za-z0-9]+$/,
            changelang === "TH"
              ? "กรุณากรอกเป็นภาษาอังกฤษและตัวเลขเท่านั้น"
              : "Please enter only English letters and numbers"
          )
          .max(
            13,
            changelang === "TH"
              ? "กรุณากรอกไม่เกิน 13 หลัก"
              : "Please enter no more than 13 characters"
          ),
        first_name: yup
          .string()
          .required(
            changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
          )
          .matches(
            /^[\p{L}\p{M}\s]+$/u,
            changelang === "TH"
              ? "กรุณากรอกเป็นตัวอักษรเท่านั้น"
              : "Please enter letters only"
          ),
        last_name: yup
          .string()
          .required(
            changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
          )
          .matches(
            /^[\p{L}\p{M}\s]+$/u,
            changelang === "TH"
              ? "กรุณากรอกเป็นตัวอักษรเท่านั้น"
              : "Please enter letters only"
          ),
        tel_no: yup
          .string()
          .nullable()
          .required(
            changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
          )
          .matches(/^\d+$/, {
            message:
              changelang === "TH"
                ? "กรุณากรอกเป็นตัวเลขเท่านั้น"
                : "Please enter numbers only",
            excludeEmptyString: true,
          })
          .max(
            10,
            changelang === "TH"
              ? "กรุณากรอกไม่เกิน 10 หลัก"
              : "Please enter no more than 10 characters"
          ),
        email: yup
          .string()
          .email(
            changelang === "TH"
              ? "กรุณากรอกเป็นอีเมลเท่านั้น"
              : "Please enter a valid email"
          )
          .nullable(
            changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
          )
        // .required(
        //   changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
        // )
        ,
        license_plate: yup.string().nullable(),
        company_name: yup
          .string()
        // .required(
        //   changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
        // )
        ,
      })
    ),
  });

  const schemaStep3 = yup.object().shape({
    object_id: yup
      .string()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      ),
    area_id: yup
      .array()
      .nullable()
      .default([])
      .when("object.access_card_types", {
        is: (value) => !!value?.require_area, // ถ้าไม่ใช่ "ผู้รับเหมา (ไม่เข้าอาคาร)"
        then: (schema) =>
          schema.min(
            1,
            changelang === "TH"
              ? "กรุณาเลือกพื้นที่อย่างน้อย 1 พื้นที่"
              : "Please select at least one area"
          ),
        otherwise: (schema) => schema.notRequired(),
      }),

    detail: yup
      .string()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      ),
    department: yup
      .object()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      ),
    contactPerson: yup
      .object()
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      ),
    startdate: yup
      .mixed()
      .test(
        "is-datetime",
        changelang === "TH"
          ? "กรุณาระบุวันที่และเวลาให้ถูกต้อง"
          : "Please enter a valid date and time",
        (value) => moment(value, "DD/MM/YYYY HH:mm", true).isValid()
      )
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )
      .test(
        "Startdate-Enddate",
        changelang === "TH"
          ? "วันที่และเวลาเริ่มต้นต้องไม่เกินวันที่และเวลาสิ้นสุด"
          : "Start date and time must not be later than the end date and time",
        function (value) {
          const { enddate } = this.parent;
          const startDate = moment(value, "DD/MM/YYYY HH:mm", true);
          const endDate = moment(enddate, "DD/MM/YYYY HH:mm", true);

          return (
            startDate.isValid() &&
            (!enddate ||
              (endDate.isValid() && startDate.isSameOrBefore(endDate)))
          );
        }
      ),
    enddate: yup
      .mixed()
      .test(
        "is-datetime",
        changelang === "TH"
          ? "กรุณาระบุวันที่และเวลาให้ถูกต้อง"
          : "Please enter a valid date and time",
        (value) => moment(value, "DD/MM/YYYY HH:mm", true).isValid()
      )
      .required(
        changelang === "TH" ? "จำเป็นต้องกรอก" : "This field is required"
      )
      .test(
        "Enddate-Startdate",
        changelang === "TH"
          ? "วันที่และเวลาสิ้นสุดต้องไม่ก่อนวันที่และเวลาเริ่มต้น"
          : "End date and time must not be earlier than the start date and time",
        function (value) {
          const { startdate } = this.parent;
          const startDate = moment(startdate, "DD/MM/YYYY HH:mm", true);
          const endDate = moment(value, "DD/MM/YYYY HH:mm", true);

          return (
            endDate.isValid() &&
            (!startdate ||
              (startDate.isValid() && endDate.isSameOrAfter(startDate)))
          );
        }
      ),
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setHasAccepted(true);
    }
  };

  const getSchema = (step) => {
    switch (step) {
      case 0:
        return schemaStep1;
      case 1:
        return schemaStep2;
      case 2:
        return schemaStep3;
      default:
        return schemaStep1;
    }
  };

  const steps =
    changelang === "TH"
      ? ["PDPA", "ข้อมูลส่วนบุคคล", "ข้อมูลการติดต่อ"]
      : ["PDPA", "Personal Information", "Contact Information"];

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const StyledCard = styled(Card)(({ theme }) => ({
    height: "60vh",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
    },
    position: "relative",
  }));

  const methods = useForm({
    resolver: yupResolver(getSchema(activeStep)),
    defaultValues: { license_plate: "" },
    mode: "onChange",
  });
  const { control, formState, watch, setValue, handleSubmit, reset } = methods;
  const { errors, isSubmitting } = formState;
  const contactListValue = methods.watch("contact_list");

  const handleNext = async (data) => {
    console.log(data);
    if (activeStep === 0 && !isChecked) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text:
          changelang === "TH"
            ? "กรุณาอ่านและยอมรับเงื่อนไข PDPA ก่อนดำเนินการต่อ"
            : "Please read and accept the PDPA terms before proceeding",
      });
      return;
    }
    if (activeStep === steps.length - 1) {
      setIsLoading(true);
      const formtokenData = formtoken || {};
      if (formtoken) {
        const dummy = {
          ...data,
          email: data?.email || "test@test.test",
          company_name: data?.company_name || "VISITOR",
          obj_id: data?.object?.id,
          objective: data?.object?.reg_obj_name,
          area_id:
            formtokenData.area_id || data?.area_id?.map((e) => e.id) || null,
          contact_personal_id: data?.contactPerson?.personal_id || null,
          contact_person_id: data?.contactPerson?.id || null,
          department_id: data?.department?.id || null,
          company_id: data?.department?.company_id || null,
          startdate: moment(data.startdate, "DD/MM/YYYY HH:mm", true).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),

          enddate: moment(data.enddate, "DD/MM/YYYY HH:mm", true).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),

          followers:
            data?.followers?.map((e) => {
              return {
                ...e,
                email: e?.email || "test@test.test",
                company_name: e?.company_name || "VISITOR",
              };
            }) || [],
          invite_id: formtoken?.id,
          invite_token: invitetoken,
        };
        const response1 = await api
          .post(`/online_register/invite`, dummy, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            if (res.data?.ret == 0) {
              Swal.fire({
                icon: "success",
                title: "สำเร็จ",
                text:
                  changelang === "TH"
                    ? "ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว"
                    : "Your information has been successfully submitted",
              });
              setIsFormSubmitted(true);
              setActiveStep(0);
              reset({});
            } else {
              Swal.fire({
                icon: "error",
                title: "ไม่สำเร็จ",
                text:
                  changelang === "TH"
                    ? "ไม่สามารถส่งข้อมูลได้ กรุณาลองใหม่อีกครั้ง"
                    : "Unable to submit data. Please try again.",
              });

            }

          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              title: "ไม่สำเร็จ",
              text:
                changelang === "TH"
                  ? "ไม่สามารถส่งข้อมูลได้ กรุณาลองใหม่อีกครั้ง"
                  : "Unable to submit data. Please try again.",
            });
          })
          .finally(() => setIsLoading(false));
      } else {
        const dummy = {
          ...data,
          email: data?.email || "test@test.test",
          company_name: data?.company_name || "VISITOR",
          obj_id: data?.object?.id,
          objective: data?.object?.reg_obj_name,
          area_id:
            formtokenData.area_id || data?.area_id?.map((e) => e.id) || null,
          contact_personal_id: data?.contactPerson?.personal_id || null,
          contact_person_id: data?.contactPerson?.id || null,
          department_id: data?.department?.id || null,
          company_id: data?.department?.company_id || null,
          startdate: moment(data.startdate, "DD/MM/YYYY HH:mm", true).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),

          enddate: moment(data.enddate, "DD/MM/YYYY HH:mm", true).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          followers: data?.followers?.map(e => { return { ...e, email: e?.email || "test@test.test", company_name: e?.company_name || "VISITOR" } }) || [],
          invite_id: formtoken?.id,
          invite_token: invitetoken,
        };

        // console.log(dummy);

        const response1 = await api
          .post(`/online_register`, dummy, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (ret) => {
            Swal.fire({
              icon: "success",
              title: "สำเร็จ",
              text:
                changelang === "TH"
                  ? "ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว"
                  : "Your information has been successfully submitted",
            });
            setIsFormSubmitted(true);
            setActiveStep(0);
            reset({});
          })
          .catch(function (error) {
            Swal.fire({
              icon: "error",
              title: "ไม่สำเร็จ",
              text:
                changelang === "TH"
                  ? "ไม่สามารถส่งข้อมูลได้ กรุณาลองใหม่อีกครั้ง"
                  : "Unable to submit data. Please try again.",
            });
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleRefresh = async () => {
  //   setIsLoading(true);
  //   if (invitetoken) {
  //     if (invitetoken.length < 5) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว",
  //         text: "กรุณาติดต่อเจ้าหน้าที่",
  //         showConfirmButton: true,
  //         confirmButtonText: "OK",
  //         allowOutsideClick: false,
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           Swal.close();
  //           navigate("/");
  //         }
  //       });
  //       setTimeout(() => {
  //         Swal.close();
  //         navigate("/");
  //       }, 5000);
  //       setIsLoading(false);
  //       return;
  //     }
  //     try {
  //       const response = await api.get(`/online_register/invite`, {
  //         params: {
  //           invite_token: invitetoken,
  //         },
  //       });

  //       if (
  //         !response?.data?.data?.[0] ||
  //         response?.data?.total === 0
  //         // || response?.data?.data?.[0].invite_status === 1
  //       ) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว",
  //           // html: "ลิงค์การเชิญนี้ไม่ถูกต้อง<br>หรือ<br>มีการลงทะเบียนแล้ว",
  //           text: "กรุณาติดต่อเจ้าหน้าที่",
  //           showConfirmButton: true,
  //           confirmButtonText: "OK",
  //           allowOutsideClick: false,
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             Swal.close();
  //             navigate("/");
  //           }
  //         });
  //         setTimeout(() => {
  //           Swal.close();
  //           navigate("/");
  //         }, 5000);

  //         setIsLoading(false);
  //       } else {
  //         setFormtoken(response?.data?.data?.[0]);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       // console.error("Error fetching data:", error);
  //       setIsLoading(false);
  //     }
  //   } else {
  //     navigate("/");
  //     setIsLoading(false);
  //   }
  // };

  const handleRefresh = async () => {
    setIsLoading(true);
    if (invitetoken) {
      if (invitetoken.length < 5) {
        Swal.fire({
          icon: "error",
          title:
            changelang === "TH"
              ? "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว"
              : "This invitation link is invalid or<br>has already been registered",
          text:
            changelang === "TH"
              ? "กรุณาติดต่อเจ้าหน้าที่"
              : "Please contact the staff",
          showConfirmButton: true,
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.close();
            navigate("/");
          }
        });
        setTimeout(() => {
          Swal.close();
          navigate("/");
        }, 5000);
        setIsLoading(false);
        return;
      }
      try {
        const response = await api.get(`/online_register/invite`, {
          params: {
            invite_token: invitetoken,
          },
        });

        const inviteData = response?.data?.data?.[0];

        if (!inviteData || response?.data?.total === 0) {
          Swal.fire({
            icon: "error",
            title:
              changelang === "TH"
                ? "ลิงค์การเชิญนี้ไม่ถูกต้อง หรือ<br>มีการลงทะเบียนแล้ว"
                : "This invitation link is invalid or<br>has already been registered",

            text:
              changelang === "TH"
                ? "กรุณาติดต่อเจ้าหน้าที่"
                : "Please contact the staff",

            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.close();
              navigate("/");
            }
          });
          setTimeout(() => {
            Swal.close();
            navigate("/");
          }, 5000);

          setIsLoading(false);
          return;
        }

        // ตรวจสอบ end_date
        const currentDate = new Date(); // วันที่ปัจจุบัน
        const endDate = new Date(inviteData.end_date); // end_date จาก response

        // ดึงเฉพาะวันที่ (รูปแบบ YYYY-MM-DD) มาเปรียบเทียบ
        const currentDateString = currentDate.toISOString().split("T")[0];
        const endDateString = endDate.toISOString().split("T")[0];

        // ถ้า end_date น้อยกว่าวันปัจจุบัน
        if (endDateString < currentDateString) {
          Swal.fire({
            icon: "error",
            title:
              changelang === "TH"
                ? "เลยเวลาลงทะเบียนแล้ว"
                : "Registration time has passed",

            text:
              changelang === "TH"
                ? "กรุณาติดต่อเจ้าหน้าที่"
                : "Please contact the staff",

            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.close();
              navigate("/");
            }
          });
          setTimeout(() => {
            Swal.close();
            navigate("/");
          }, 5000);

          setIsLoading(false);
          return;
        }

        // ถ้า end_date ยังไม่หมดอายุ (>= ปัจจุบัน)
        setFormtoken(inviteData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      navigate("/");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log(formtoken);
  }, [formtoken]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const onSubmit = (data) => {
    handleNext(data);
  };
  const onError = (errors) => {
    Swal.fire({
      icon: "error",
      // title: "Oops...",
      text:
        changelang === "TH"
          ? "กรุณาตรวจสอบข้อมูลให้ถูกต้อง"
          : "Please check the information for accuracy",
    });
    // toast.error("กรุณาตรวจสอบข้อมูลให้ถูกต้อง");
    console.error(errors);
  };

  // console.log(activeStep);

  return (
    <div className="bg">
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(255, 255, 255, 0.7)"
          zIndex={1300}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <FormProvider {...methods}>
        <Container className="content-center pt-32">
          <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className="kanit-bold"
              sx={{
                // display: {
                //   xs: "none",
                //   sm: "flex",
                // },
                "@media (max-width: 375px)": {
                  display: "none",
                },
                ".MuiStepLabel-label": {
                  fontSize: "1.6rem",
                  color: "#9CA3AF",
                  fontFamily: "Kanit , sans-serif",
                  fontWeight: 500,
                  marginTop: "8px !important",
                },

                ".MuiStepLabel-label.Mui-active": {
                  color: "#05A2F3",
                },
                ".MuiStepLabel-label.Mui-completed": {
                  color: "#05A2F3",
                },
                ".MuiStepIcon-root": {
                  color: "#9CA3AF",
                  fontSize: "2.4rem",
                },
                ".MuiStepIcon-root.Mui-active": {
                  color: "#05A2F3",
                },
                ".MuiStepIcon-root.Mui-completed": {
                  color: "#05A2F3",
                },
              }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel className="font-title">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card
              className="mt-16 shadow rounded-2xl "
              sx={{
                position: "relative",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  textAlign: "center",
                  padding: 0,
                }}
              >
                {activeStep === 0 && (
                  <Step1
                    control={methods.control}
                    setIsChecked={setIsChecked}
                    changelang={changelang}
                    setChangelang={setChangelang}
                    isScrollEnd={isScrollEnd}
                    hasAccepted={hasAccepted}
                    setIsScrollEnd={setIsScrollEnd}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                )}
                {activeStep === 1 && (
                  <Step2
                    control={methods.control}
                    setValue={methods.setValue}
                    watch={methods.watch}
                    formState={methods.formState}
                    changelang={changelang}
                    followers={followers}
                    setFollowers={setFollowers}
                  />
                )}
                {activeStep === 2 && (
                  <Step3
                    // control={methods.control}
                    // watch={methods.watch}
                    // formState={methods.formState}
                    // followers={followers}
                    // setFollowers={setFollowers}
                    // contactListValue={contactListValue}
                    changelang={changelang}
                    formtoken={formtoken}
                  />
                )}
              </CardContent>
              <CardActions
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: "0px",
                  margin: 0,
                  width: "100%",
                  background: "#ffffff",
                  zIndex: 10,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: 1.5,
                    background: "#ffffff",
                    zIndex: 10,
                  }}
                >
                  <Button
                    className={activeStep === 0 ? "hidden bt-back" : "bt-back"}
                    variant="outlined"
                    onClick={handleBack}
                    disabled={isSubmitting}
                  >
                    ย้อนกลับ
                  </Button>
                  <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
                    <FormControlLabel
                      sx={{
                        ".MuiTypography-root": {
                          fontSize: "1.2rem",
                          fontFamily: "Kanit , sans-serif",
                          fontWeight: 400,
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: "2.4rem",
                          fontFamily: "Kanit , sans-serif",
                          fontWeight: 400,
                        },
                      }}
                      control={
                        <Checkbox
                          disabled={!isScrollEnd && !hasAccepted}
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        changelang == "TH"
                          ? "ฉันได้อ่านและยอมรับเงื่อนไข PDPA"
                          : "I have read and accept the PDPA terms"
                      }
                    />
                  </Box>
                  <Button
                    // type="submit"
                    variant="contained"
                    className="bt-submit"
                    disabled={isSubmitting}
                    onClick={() => methods.handleSubmit(onSubmit, onError)()}
                  >
                    {activeStep === steps.length - 1
                      ? changelang === "TH"
                        ? "สำเร็จ"
                        : "Success"
                      : changelang === "TH"
                        ? "ถัดไป"
                        : "Next"}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </form>
        </Container>
      </FormProvider>
    </div>
  );
};

export default RegistrationForm;
