import React from "react";
import RegistrationForm from "./Components/RegistrationForm";
import Approve from "./Components/Approve/Approve";
import Header from "./Components/Header/Header";

import { Box } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100vh",
          bgcolor: "#f1f5f9",
        }}
      >
        <Header />
        <Box sx={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<RegistrationForm />} />
            <Route path="/approval" element={<Approve />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
