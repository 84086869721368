import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Autocomplete,
  Typography,
  ListSubheader,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/en-gb";
import { api, apiOLR } from "../api/index";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import * as qs from "qs";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const departments = ["แผนก A", "แผนก B", "แผนก C"];
const contactPersons = ["Mr. A", "Ms. B", "Dr. C"];
const contactList = [
  "ผู้รับเหมา(ไม่เข้าอาคาร)",
  "ส่งเอกสาร",
  "ผู้รับเหมา (เข้าอาคาร)",
  "ส่งสินค้า (เข้าอาคาร)",
  "VIP",
  "Guest",
  "ผู้มาติดต่อ",
  "สถานศึกษา",
  "Co-working space",
];

const Step3 = ({ formtoken, changelang }) => {
  const methods = useFormContext();
  const {
    control,
    formState,
    watch,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
  } = methods;
  const { errors } = formState;
  const [personType, setPersonType] = useState([]);
  const [areas, setAreas] = useState([]);
  const [department, setDepartment] = useState([]);
  const [persons, setPersons] = useState([]);
  const [dummyPersons, setDummyPersons] = useState([]);
  const [cilkup, setCilkup] = useState(0);

  const colorGroups = [
    "ผู้รับเหมา (ไม่เข้าอาคาร)",
    "ส่งเอกสาร",
    "ผู้รับเหมา (เข้าอาคาร)",
    "ส่งสินค้า (เข้าอาคาร)",
    "ผู้มาติดต่อ",
    "สถานศึกษา",
    "Co-working space",
  ];

  const selectedObject = watch("object");
  // console.log(selectedObject);
  const isAreaRequired = !!selectedObject?.access_card_types?.require_area;

  // useEffect(() => {
  //   if (formtoken) {
  //     if (formtoken?.area_id?.length) {
  //       const defaultAreas = areas.filter((area) =>
  //         formtoken.area_id.includes(area.id)
  //       );
  //       setValue("area_id", defaultAreas);
  //     }
  //     if (formtoken?.contact_person_id) {
  //       const defaultPerson = persons.find(
  //         (person) => person.id === formtoken.contact_person_id
  //       );
  //       if (defaultPerson) {
  //         console.log("Default person:", defaultPerson);
  //         setValue("contactPerson", defaultPerson);
  //       }
  //     }

  //     // const rawDate = formtoken.start_date;
  //     // const startDateValue = rawDate
  //     //   ? moment(rawDate, "YYYY-MM-DD HH:mm:ss", true)
  //     //   : null;
  //     // if (startDateValue && startDateValue.isValid()) {
  //     //   setValue("startdate", startDateValue.format("DD/MM/YYYY"));
  //     // }

  //     const rawDate = formtoken.start_date;
  //     const startDateValue = rawDate
  //       ? moment(rawDate, "YYYY-MM-DD HH:mm:ss", true).format("DD/MM/YYYY")
  //       : null;
  //     if (startDateValue) {
  //       setValue("startdate", startDateValue);
  //     }

  //     const rawEndDate = formtoken.end_date;
  //     const endDateValue = rawEndDate
  //       ? moment(rawEndDate, "YYYY-MM-DD HH:mm:ss", true).format("DD/MM/YYYY")
  //       : null;
  //     if (endDateValue) {
  //       setValue("enddate", endDateValue);
  //     }

  //     // const rawEndDate = formtoken.end_date;
  //     // const endDateValue = rawEndDate
  //     //   ? moment(rawEndDate, "YYYY-MM-DD HH:mm:ss", true)
  //     //   : null;
  //     // if (endDateValue && endDateValue.isValid()) {
  //     //   setValue("enddate", endDateValue.format("DD/MM/YYYY"));
  //     // }

  //     if (formtoken?.reg_obj_id && formtoken?.reg_objective) {
  //       const selectedOption = personType.find(
  //         (option) => option.id === formtoken.reg_obj_id
  //       );

  //       if (selectedOption) {
  //         setValue("object_id", selectedOption.id);
  //         setValue("object", selectedOption);
  //       }
  //     }
  //     if (formtoken?.contact_department_id) {
  //       const selectedDepartment = department.find(
  //         (dep) => dep.id === formtoken.contact_department_id
  //       );

  //       if (selectedDepartment) {
  //         setValue("department", selectedDepartment);
  //       }
  //     }

  //     setValue("detail", formtoken.reg_detail);
  //   }
  // }, [formtoken, areas, department, personType, setValue]);

  useEffect(() => {
    handleRefresh();
  }, []);
  async function handleRefresh(data) {
    if (formtoken) {
      if (formtoken?.area_id?.length) {
        const defaultAreas = await api.get(
          `/online_register/areas`,
          {
            params: { id: formtoken?.area_id },
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // const defaultAreas = areas.filter((area) =>
        //   formtoken.area_id.includes(area.id)
        // );
        setValue("area_id", defaultAreas?.data?.data);
      }
      if (formtoken?.contact_person_id) {
        // const defaultPerson = persons.find(
        //   (person) => person.id === formtoken.contact_person_id
        // );
        const defaultPerson = await api.get(
          `/online_register/person`,
          {
            params: {
              id: formtoken.contact_person_id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!!defaultPerson?.data?.data?.[0]) {
          console.log("Default person:", defaultPerson?.data?.data?.[0]);
          setValue("contactPerson", defaultPerson?.data?.data?.[0]);
        }
      }

      // const rawDate = formtoken.start_date;
      // const startDateValue = rawDate
      //   ? moment(rawDate, "YYYY-MM-DD HH:mm:ss", true)
      //   : null;
      // if (startDateValue && startDateValue.isValid()) {
      //   setValue("startdate", startDateValue.format("DD/MM/YYYY"));
      // }

      const rawDate = formtoken.start_date;
      const startDateValue = rawDate
        ? moment(rawDate, "YYYY-MM-DD HH:mm:ss", true).format("DD/MM/YYYY HH:mm")
        : null;
      if (startDateValue) {
        setValue("startdate", startDateValue);
      }

      const rawEndDate = formtoken.end_date;
      const endDateValue = rawEndDate
        ? moment(rawEndDate, "YYYY-MM-DD HH:mm:ss", true).format("DD/MM/YYYY HH:mm")
        : null;
      if (endDateValue) {
        setValue("enddate", endDateValue);
      }

      // const rawEndDate = formtoken.end_date;
      // const endDateValue = rawEndDate
      //   ? moment(rawEndDate, "YYYY-MM-DD HH:mm:ss", true)
      //   : null;
      // if (endDateValue && endDateValue.isValid()) {
      //   setValue("enddate", endDateValue.format("DD/MM/YYYY"));
      // }

      if (formtoken?.reg_obj_id && formtoken?.reg_objective) {
        // const selectedOption = personType.find(
        //   (option) => option.id === formtoken.reg_obj_id
        // );
        const selectedOption = await api.get(
          `/online_register/online_register_objective`,
          {
            params: {
              id: formtoken?.reg_obj_id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!!selectedOption?.data?.data?.[0]) {
          setValue("object_id", selectedOption?.data?.data?.[0]?.id);
          setValue("object", selectedOption?.data?.data?.[0]);
        }
      }
      if (formtoken?.contact_department_id) {
        // const selectedDepartment = department.find(
        //   (dep) => dep.id === formtoken.contact_department_id
        // );
        const selectedDepartment = await api.get(
          `/online_register/department`,
          {
            params: {
              id: formtoken.contact_department_id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!!selectedDepartment?.data?.data?.[0]) {
          setValue("department", selectedDepartment?.data?.data?.[0]);
        }
      }

      setValue("detail", formtoken.reg_detail);
    } else {
      const responsepersontype = await api.get(
        `/online_register/online_register_objective`,
        {
          params: {
            isDefault: true,
            isDeleted: false,
            sort: "asc",
            order_by: "priority"
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPersonType(responsepersontype?.data?.data || []);

      const responsedepartment = await api.get(
        `/online_register/department`,
        {
          params: {
            "is_public": 1
          }
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setDepartment(responsedepartment?.data?.data || []);
    }
    const responseareas = await api.get(
      `/online_register/areas`,
      { params: {} },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setAreas(responseareas?.data?.data || []);
  }

  const handleSearchPersons = async (data) => {
    if (data?.length) {
      api
        .get(
          `/online_register/person`,
          {
            params: {
              "search_cols[first_name|last_name|personal_id]":
                data || undefined,
              department_id: watch("department")?.id || undefined,
              person_type: 1,
              blacklist: 0,
              isDeleted: false,
              "json[department.is_public]": 1
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((e) => {
          if (e?.data?.count > 0 && e?.data?.ret == 0) {
            //   // setPartner(e)
            setPersons(e?.data?.data);
          } else {
            setPersons([]);
          }
        });
    } else {
      setPersons(dummyPersons);
    }
  };
  const handleGetPersons = async (data) => {
    const responseperson = await api.get(
      `/online_register/person`,
      {
        params: {
          department_id: data?.id || undefined,
          person_type: 1,
          blacklist: 0,
          isDeleted: false,
          limit: 50,
          "json[department.is_public]": 1
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setDummyPersons(responseperson?.data?.data || []);
    setPersons(responseperson?.data?.data || []);
  };

  useEffect(() => {
    handleGetPersons(watch("department"));
  }, [watch("department")]);

  return (
    <div className="p-32" style={{ marginBottom: "28px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-20 display-center">
          <Grid item xs={12} md={6} className="mb-20">
            <Controller
              name="object_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  noOptionsText={
                    changelang === "TH"
                      ? "กรุณากรอกข้อมูลเพื่อค้นหา"
                      : "Please enter information to search"
                  }
                  disabled={
                    !!formtoken?.reg_objective || !!formtoken?.reg_obj_id
                  }
                  {...field}
                  options={personType}
                  getOptionLabel={(option) => {
                    return typeof option === "string"
                      ? option
                      : option?.reg_obj_name;
                  }}
                  value={field.value ? watch("object") : null}
                  onChange={(_, data) => {
                    field.onChange(data?.id || "");
                    setValue("object", data || null);
                    setCilkup((pre) => pre + 1);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        changelang === "TH" ? "ประเภทบุคคล" : "Person Type"
                      }
                      variant="outlined"
                      required
                      InputLabelProps={{
                        style: {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                        },
                      }}
                      sx={{
                        ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                          color: "red",
                        },
                        "& .MuiInputBase-root": {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: "24px",
                        },
                      }}
                      error={!!errors.object_id}
                      helperText={errors?.object_id?.message}
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography
                            variant="body1"
                            color="initial"
                            sx={{
                              fontSize: "16px !important",
                              fontFamily: "Kanit, sans-serif !important",
                            }}
                          >
                            {option?.reg_obj_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className="mb-20">
          <Controller
            name="area_id"
            control={control}
            defaultValue={[]}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  multiple
                  options={areas}
                  noOptionsText={
                    changelang === "TH"
                      ? "กรุณากรอกข้อมูลเพื่อค้นหา"
                      : "Please enter information to search"
                  }
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option?.area_name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterSelectedOptions
                  disabled={!!formtoken?.area_id?.length}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        changelang === "TH"
                          ? "ขออนุญาตเข้าพื้นที่"
                          : "Request Access"
                      }
                      variant="outlined"
                      fullWidth
                      required={isAreaRequired}
                      inputProps={{
                        ...params.inputProps,
                        required: isAreaRequired,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                        },
                      }}
                      sx={{
                        ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                          color: "red",
                        },
                        "& .MuiInputBase-root": {
                          fontSize: "16px",
                          fontFamily: "Kanit, sans-serif",
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: "24px",
                        },
                      }}
                      error={!!errors.area_id}
                      helperText={errors?.area_id?.message}
                    />
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option?.area_name}
                        sx={{
                          background: "#05A2F3",
                          ".MuiChip-label": {
                            padding: "12px",
                            fontSize: "16px",
                            fontFamily: "Kanit, sans-serif !important",
                            fontWeight: 400,
                            color: "#ffffff",
                          },
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option}>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Typography
                              variant="body1"
                              color="initial"
                              sx={{
                                fontSize: "16px !important",
                                fontFamily: "Kanit, sans-serif !important",
                              }}
                            >
                              {option?.area_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} className="mb-20">
          <Controller
            name="detail"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ""}
                disabled={!!formtoken?.reg_detail?.length}
                required
                InputLabelProps={{
                  // shrink: true,
                  style: {
                    fontSize: "16px",
                    fontFamily: "Kanit, sans-serif",
                  },
                }}
                sx={{
                  ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                    color: "red",
                  },
                  "& .MuiInputBase-root": {
                    fontSize: "16px",
                    fontFamily: "Kanit, sans-serif",
                  },
                }}
                error={!!errors.detail}
                helperText={errors?.detail?.message}
                label={changelang === "TH" ? "วัตถุประสงค์" : "Purpose"}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={6} className="mb-20">
          <Controller
            name="department"
            control={control}
            render={({ field }) => (
              <Autocomplete
                noOptionsText={
                  changelang === "TH"
                    ? "กรุณากรอกข้อมูลเพื่อค้นหา"
                    : "Please enter information to search"
                }
                options={department}
                disabled={!!formtoken?.contact_department_id}
                getOptionLabel={(option) => option?.department_name || ""}
                onChange={(_, data) => {
                  field.onChange(data);
                  setValue("contactPerson", null);
                  setCilkup((pre) => pre + 1);
                }}
                value={watch("department") || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={changelang === "TH" ? "แผนก" : "Department"}
                    variant="outlined"
                    required
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                    }}
                    sx={{
                      ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                      "& .MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                    }}
                    error={!!errors.department}
                    helperText={errors?.department?.message}
                    fullWidth
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography
                          variant="body1"
                          color="initial"
                          sx={{
                            fontSize: "16px !important",
                            fontFamily: "Kanit, sans-serif !important",
                          }}
                        >
                          {option?.department_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontSize: "16px !important",
                            fontFamily: "Kanit, sans-serif !important",
                          }}
                        >
                          {option?.company?.company_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="mb-20">
          <Controller
            name="contactPerson"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                noOptionsText={
                  changelang === "TH" ? "ไม่มีรายการ" : "No items available"
                }
                getOptionLabel={(option) => {
                  return `${option?.first_name || ""} ${option?.last_name || ""
                    }`;
                }}
                options={persons}
                filterSelectedOptions
                value={value || null}
                disabled={!!formtoken?.contact_person_id}
                onChange={(event, newValue) => {
                  onChange(newValue || null);

                  if (newValue?.department) {
                    setValue(`department`, newValue.department || null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
                // onInputChange={(event, newInputValue) => {
                //   console.log("Search input:", newInputValue);
                // }}
                onInputChange={(event, newInputValue) => {
                  if (event?.target?.value?.length) {
                    handleSearchPersons(event?.target?.value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={changelang === "TH" ? "ผู้ติดต่อ" : "Contact Person"}
                    fullWidth
                    required
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                    }}
                    sx={{
                      ".MuiInputLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                      "& .MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                    }}
                    error={!!errors.contactPerson}
                    helperText={errors?.contactPerson?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Kanit, sans-serif",
                          }}
                        >
                          {option?.first_name} {option?.last_name}
                        </Typography>
                        {/* <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Kanit, sans-serif",
                          }}
                        >
                          {option?.first_name} {option?.last_name}
                        </Typography> */}
                      </Grid>
                    </Grid>
                  </li>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} className="mb-20">
          <Controller
            name="startdate"
            control={control}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-gb"
              >
                <FormControl fullWidth error={!!errors.startdate}>
                  <DateTimePicker
                    {...field}
                    value={
                      moment(field.value, "DD/MM/YYYY HH:mm", true).isValid()
                        ? moment(field.value, "DD/MM/YYYY HH:mm", true)
                        : null
                    }
                    disabled={!!formtoken?.start_date}
                    label={
                      changelang === "TH"
                        ? "วันที่และเวลาเริ่มต้น"
                        : "Start Date & Time"
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "16px !important",
                        fontFamily: "Kanit, sans-serif !important",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                      ".MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!errors.startdate,
                        helperText: errors?.startdate?.message,
                      },
                      layout: {
                        sx: {
                          borderRadius: "20px",
                          "& .MuiPickersDay-root": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          "& .MuiPickersYear-yearButton": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          ".MuiPickersCalendarHeader-label": {
                            fontSize: "18px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                          ".MuiDayCalendar-weekDayLabel": {
                            fontSize: "12px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                        },
                      },
                      leftArrowIcon: { fontSize: "large" },
                      rightArrowIcon: { fontSize: "large" },
                      switchViewIcon: { fontSize: "large" },
                      field: {
                        clearable: true,
                      },
                    }}
                    inputFormat="DD/MM/YYYY HH:mm"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={!!errors.startdate}
                        helperText={errors?.startdate?.message}
                        onKeyDown={(e) => null}
                      />
                    )}
                    onChange={(newValue) => {
                      setValue(
                        "startdate",
                        moment(newValue).format("DD/MM/YYYY HH:mm")
                      );
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          />
        </Grid>

        <Grid item xs={6} className="mb-20">
          <Controller
            name="enddate"
            control={control}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-gb"
              >
                <FormControl fullWidth error={!!errors.enddate}>
                  <DateTimePicker
                    {...field}
                    value={
                      moment(field.value, "DD/MM/YYYY HH:mm", true).isValid()
                        ? moment(field.value, "DD/MM/YYYY HH:mm", true)
                        : null
                    }
                    disabled={!!formtoken?.end_date}
                    label={
                      changelang === "TH"
                        ? "วันที่และเวลาสิ้นสุด"
                        : "End Date & Time"
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        fontSize: "16px",
                        fontFamily: "Kanit, sans-serif",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "16px !important",
                        fontFamily: "Kanit, sans-serif !important",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "24px",
                      },
                      ".MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                        color: "red",
                      },
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!errors.enddate,
                        helperText: errors?.enddate?.message,
                      },
                      layout: {
                        sx: {
                          borderRadius: "20px",
                          "& .MuiPickersDay-root": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          "& .MuiPickersYear-yearButton": {
                            fontSize: "12px ",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                            "&.Mui-selected": {
                              backgroundColor: "#05A2F3",
                            },
                          },
                          ".MuiPickersCalendarHeader-label": {
                            fontSize: "18px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                          ".MuiDayCalendar-weekDayLabel": {
                            fontSize: "12px !important",
                            fontWeight: "500",
                            fontFamily: "Kanit, sans-serif !important",
                          },
                        },
                      },
                      leftArrowIcon: { fontSize: "large" },
                      rightArrowIcon: { fontSize: "large" },
                      switchViewIcon: { fontSize: "large" },
                      field: {
                        clearable: true,
                      },
                    }}
                    inputFormat="DD/MM/YYYY HH:mm"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={!!errors.enddate}
                        helperText={errors?.enddate?.message}
                        onKeyDown={(e) => null}
                      />
                    )}
                    onChange={(newValue) => {
                      setValue(
                        "enddate",
                        moment(newValue).format("DD/MM/YYYY HH:mm")
                      );
                    }}
                  />
                </FormControl>
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Step3;
